import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { RightArrown } from '../../../shared/Icons/RightArrow';

const ProductLinks = ({ list }) => {
  if (!list?.length) return null;
  return (
    <div className="flex justify-between flex-wrap">
      {list?.map(({ title, url }) => (
        <Link
          key={url}
          to={'/solutions/' + url}
          className="flex items-center justify-between w-45 py-8 border-bottom border-gray-500 w-full-mobile text-white font-medium text-xl-xxl text-lg"
        >
          {title}
          <RightArrown fill="#2489CD" />
        </Link>
      ))}
    </div>
  );
};

ProductLinks.propTypes = {
  list: PropTypes.array,
};

export default ProductLinks;
